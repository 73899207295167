import React from "react"
import { Helmet } from "react-helmet"

// Styles
import "../styles/custom.css"
import "../styles/flexslider.css"
import "../styles/theme.css"
// Components
import HomePage from "./Home"

export default function Home() {
  return (
    <>
      <Helmet></Helmet>
      <HomePage />
    </>
  )
}
